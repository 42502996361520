import styled from "@emotion/styled"
import React from "react"
import Layout from "../../components/layout/Layout"
import { BlueBtn } from "../../components/layout/styledComponents.js"
import Seo from "../../components/seo"

const Table = styled.div`
  background: ${props => props.theme.bgLight};
  padding: 16px;
  margin: 32px 0;

  > div {
    border: ${props => `1px solid ${props.theme.black}`};
    padding: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    :last-of-type {
      margin: 0;
    }

    h3,
    p {
      margin: 0;
    }

    h3 {
      width: 31%;
    }

    p {
      width: 66%;
    }
  }

  @media (min-width: 768px) {
    padding: 32px;
  }
`

export default function Anesthesia() {
  return (
    <Layout learnMore>
      <Seo
        title="Dental Anesthesia | Deep Sedation Dentistry Colorado Springs, CO - pikespeakoralsurgery"
        description="Dr. Michael Kunkel, receives the highest level of licensure for dental practitioners to perform anesthesia offering deep sedation or general anesthesia."
      />
      <section className="container" style={{ margin: "128px auto" }}>
        <p>
          Many patients express feeling anxiety before a surgical procedure.
          This is a normal feeling and the surgical staff at Pikes Peak Oral
          Surgery & Dental Implant Center strives to ensure that you feel as
          comfortable as possible for your procedure. We offer many options for
          sedation when you undergo an oral surgical procedure at Pikes Peak
          Oral Surgery & Dental Implant Center. The methods used often depend on
          the preference of the patient and the nature of the surgical
          procedure.
        </p>

        <Table>
          <h3>Anesthesia Options</h3>

          <div>
            <h3>Local Anesthesia</h3>
            <p>
              A local anesthetic is administered to the area that the surgery is
              performed and can be used in combination with other anesthetic
              options, such as nitrous oxide.
            </p>
          </div>

          <div>
            <h3>Nitrous Oxide Sedation</h3>
            <p>
              Nitrous oxide sedation is more commonly known as laughing gas and
              is often utilized when patients experience mild to severe anxiety
              before the procedure. A mixture of nitrous oxide and oxygen is
              administered through a face mask or nasal breathing apparatus.
            </p>
          </div>

          <div>
            <h3>Intravenous (IV) Sedation General Anesthesia</h3>
            <p>
              IV sedation is administered through an intravenous line and allows
              the patient to fall completely asleep for the entire duration of
              the procedure. This option allows for varying degrees of
              anesthetic tailored to the needs of the patient. IV anesthesia is
              commonly used to facilitate surgical procedures such as dental
              implant placement, wisdom teeth removal, bone grafting, and many
              other invasive surgical procedures. This anesthetic option can
              also be employed when patients experience a high level of anxiety.
            </p>
          </div>

          <div>
            <h3>Hospital or Surgery Center-Based General Anesthesia</h3>
            <p>
              Hospital or Surgery Center-Based General Anesthesia is
              administered by an anesthesiologist. If you undergo an extensive
              procedure, such as jaw reconstruction or a facial trauma surgery,
              you may undergo general anesthesia in a hospital or surgery
              center.
            </p>
          </div>
        </Table>

        <h3>Oral surgeons are highly qualified to administer anesthesia</h3>

        <p>
          Oral surgeons must receive and complete extensive training in
          performing anesthesia before they go into oral surgery practice.
          During his residency, Dr. Kunkel spent four months as a dedicated
          Anesthesia Resident treating adult patients in Wishard and University
          hospitals in Indianapolis. In addition, he spent two months as a
          dedicated Pediatric Anesthesia Resident at Riley Children’s Hospital.
          The remainder of his four-year residency was spent actively treating
          patients with a variety of anesthetic techniques with a focus on
          anesthetic management of oral surgery patients.
        </p>

        <p>
          Oral surgeons, like Dr. Michael Kunkel, receive the highest level of
          licensure for dental practitioners to perform anesthesia. This allows
          him to perform deep sedation or general anesthesia, which is often
          necessary to comfortably undergo certain procedures. This is in
          contrast to most general dentists who are often licensed for light
          conscious sedation only.
        </p>

        <a
          href="#contact"
          style={{
            display: "block",
            margin: "64px auto 0",
            width: "max-content",
          }}
        >
          <BlueBtn>Book an appointment now</BlueBtn>
        </a>
      </section>
    </Layout>
  )
}
